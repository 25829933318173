<template>
	<h1>Icons</h1>
	<div class="icn-wrap text-block bump-b-30">
		<BaseIcon name="arthur-logo" />
		<BaseIcon name="arthur-emblem-large" />
		<BaseIcon name="skull-large" />
	</div>
	<div class="icn-wrap text-block bump-b-30">
		<BaseIcon name="arthur-emblem" />
		<BaseIcon name="search" />
		<BaseIcon name="link" />
		<BaseIcon name="twitter" />
		<BaseIcon name="instagram" />
		<BaseIcon name="facebook" />
		<BaseIcon name="linkedin" />
		<BaseIcon name="email" />
		<BaseIcon name="calendar" />
		<BaseIcon name="room" />
		<BaseIcon name="skull" />
		<BaseIcon name="x-close" />
		<BaseIcon name="mark-good" />
		<BaseIcon name="mark-bad" />
		<BaseIcon name="invalid" />
		<BaseIcon name="expand" />
		<BaseIcon name="extension" />
		<BaseIcon name="remove" />
		<BaseIcon name="add" />
		<BaseIcon name="download" />
		<BaseIcon name="arrow-up" />
		<BaseIcon name="arrow-right" />
		<BaseIcon name="arrow-down" />
		<BaseIcon name="arrow-left" />
		<BaseIcon name="collect" />
		<BaseIcon name="collect-outline" />
		<BaseIcon name="collect-on" />
		<BaseIcon name="uncollect" />
		<BaseIcon name="star-outline" />
		<BaseIcon name="star" />
		<BaseIcon name="size-l" />
		<BaseIcon name="size-m" />
		<BaseIcon name="size-s" />
		<BaseIcon name="sort" />
		<BaseIcon name="plus" />
		<BaseIcon name="radio" />
		<BaseIcon name="radio-sel" />
		<BaseIcon name="select" />
		<BaseIcon name="select-on" />
		<BaseIcon name="lock" />
		<BaseIcon name="unlock" />
		<BaseIcon name="options" />
		<BaseIcon name="copy" />
	</div>
	<div class="icn-wrap text-block bump-b-30">
		<BaseIcon color="#d00" name="arthur-emblem" />
		<BaseIcon color="#d00" name="search" />
		<BaseIcon color="#d00" name="link" />
		<BaseIcon color="#d00" name="twitter" />
		<BaseIcon color="#d00" name="instagram" />
		<BaseIcon color="#d00" name="facebook" />
		<BaseIcon color="#d00" name="linkedin" />
		<BaseIcon color="#d00" name="email" />
		<BaseIcon color="#d00" name="calendar" />
		<BaseIcon color="#d00" name="room" />
		<BaseIcon color="#d00" name="skull" />
		<BaseIcon color="#d00" name="x-close" />
		<BaseIcon color="#d00" name="mark-good" />
		<BaseIcon color="#d00" name="mark-bad" />
		<BaseIcon color="#d00" name="invalid" />
		<BaseIcon color="#d00" name="expand" />
		<BaseIcon color="#d00" name="extension" />
		<BaseIcon color="#d00" name="remove" />
		<BaseIcon color="#d00" name="add" />
		<BaseIcon color="#d00" name="download" />
		<BaseIcon color="#d00" name="arrow-up" />
		<BaseIcon color="#d00" name="arrow-right" />
		<BaseIcon color="#d00" name="arrow-down" />
		<BaseIcon color="#d00" name="arrow-left" />
		<BaseIcon color="#d00" name="collect-outline" />
		<BaseIcon color="#d00" name="collect" />
		<BaseIcon color="#d00" name="uncollect" />
		<BaseIcon color="#d00" name="star-outline" />
		<BaseIcon color="#d00" name="star" />
		<BaseIcon color="#d00" name="size-l" />
		<BaseIcon color="#d00" name="size-m" />
		<BaseIcon color="#d00" name="size-s" />
		<BaseIcon color="#d00" name="sort" />
		<BaseIcon color="#d00" name="plus" />
		<BaseIcon color="#d00" name="radio" />
		<BaseIcon color="#d00" name="radio-sel" />
		<BaseIcon color="#d00" name="select" />
		<BaseIcon color="#d00" name="select-on" />
		<BaseIcon color="#d00" name="lock" />
		<BaseIcon color="#d00" name="unlock" />
		<BaseIcon color="#d00" name="options" />
		<BaseIcon color="#d00" name="copy" />
	</div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocComponentsIcons',
	components: { BaseIcon },
	setup() {
		injectMetaData({
			title: 'Doc: Icons',
		})
	},
}
</script>

<style scoped lang="scss">
.icn-wrap {
	display: flex;
	gap: 0.1rem;
	flex-wrap: wrap;
}
.icn-wrap:deep() .icn {
	background: #fff;
	padding: 0.1rem;
	box-shadow: 0 0 0.2rem $black-10;
	box-sizing: content-box;
}
</style>
